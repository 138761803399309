import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HelpToContentsLink from './helpToContentsLink';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ForexFlowData from './forexFlowData';
import FlowGraph from '../flow/flowGraph';
import { isMobile } from '../common/deviceDetector';
import { text } from "../languageSelection";

const styles = theme => ({
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'justify'
    },
    arrow: {
      marginBottom: '-6px'
    },
    text: {
        maxWidth: '800pt',
        textAlign: 'justify'
    },
    diagram: {
        height: 650,
        width: 1100
    },
    diagramMobile: {
        zoom: "0.3",
        height: 650,
        width: 1100
    },
    list1_Mobile: {
        paddingInlineStart: "14px",
        maxWidth: '800pt'
    },
});

class HelpIBForex extends React.Component {
    render() {
        const { classes } = this.props;
        let language = 'ru';

        return (
            <Card className={classes.card}>
              <CardContent>
                <HelpToContentsLink/>

                <div className={classes.text}>
                <p/>
                Требования к подаче доходов/убытков по сделкам с Forex являются
                довольно размытыми. В общем случае, при простой конвертации
                валюты перед покупкой финансового инструмента в данной валюте
                такая конвертация не учитывается в сделках в налоговой
                декларации, однако, если конвертации валюты происходят часто и 
                имеют целью получение прибыли, то данные сделки необходимо
                указывать в налоговой декларации. При этом явного 
                количественного критерия различия этих двух случаев пока нет.
                <p/>
                Forex сделки в отчете Interactive Brokers не предоставляют
                информации о закрытом лоте. Закрываемые в каждой конкретной 
                сделке лоты могут быть получены только на основе анализа
                истории сделок. Таким образом, в отличие от прочих финансовых 
                инструментов, для расчета налоговой декларации по которым,
                не требуется информации по сделкам, совершенным в годы, 
                предшествующие отчетному, для сделок Forex эта информация 
                требуется.
                <p/>
                Этого можно достичь одним из двух способов:
                <ul className={isMobile ? classes.list1_Mobile : ""}>
                    <li>Загрузив в FinParser отчеты IB в формате CSV за весь 
                        период с момента открытия брокерского счета до конца
                        отчетного периода.
                    </li>
                    <li>Загрузив в FinParser отчет IB в формате CSV за 
                        отчетный период + список открытых позиций Forex на 
                        момент начала отчетного периода в формате FinParser 
                        (Excel).
                    </li>
                </ul>
                <p/>
                <Typography id="1" variant="h5" component="h2">
                    Схема генерации декларации с учетом сделок Forex
                </Typography>
                  <p/>
                  {isMobile
                   ? <TransformWrapper>
                       <TransformComponent>
                         <div className={classes.diagramMobile}>
                           <FlowGraph data={ForexFlowData}/>
                         </div>
                       </TransformComponent>
                     </TransformWrapper>
                   : <div className={classes.diagram}>
                       {text(language,
                             <FlowGraph data={ForexFlowData}/>)}
                     </div>
                  }
                <p/>
                Второй способ требует более подробных пояснений. Например,
                счет был открыт в 2018 году, а декларация подается за 2020-й.
                Необходимо загрузить в FinParser отчет за 2018 год (с момента 
                открытия счета до конца года) + отчеты за 2019 и за 2020 годы 
                и сгенерировать файл налоговой декларации (в том или 
                ином формате), а также формат <b>"FinParser (Универсальный 
                формат в Excel)" </b>(<Link component={NavLink}
                                to="/help/FinParserFormat">
                                Подробнее
                        </Link>
                        <Link component={NavLink} to="/help/FinParserFormat">
                            <ArrowForwardIcon className={classes.arrow}
                                                fontSize="small"/>
                        </Link>).
                <p/>
                В следующем году полученный файл в формате FinParser 
                загружается совместно с отчетом за 2021 год. В свою очередь,
                уже на основе этих входных данных могут быть получены файл
                налоговой декларации + сделки и позиции в формате FinParser 
                по итогам  2021 года, который далее может быть использован в 
                качестве  исходных данных для получения налоговой декларации 
                за 2022 год (совместно с отчетом брокера за этот период)
                и т.д.
                <p/>
                В качестве кодов доходов по операциям Forex могут быть выбраны:
                <ul className={isMobile ? classes.list1_Mobile : ""}>
                    <li><b>1535</b> - Доходы по операциям с производными финансовыми инструментами</li>
                    <li><b>1520</b> - Доходы от реализации имущества (доли), кроме ценных бумаг</li>
                    <li><b>2900</b> - Доходы, полученные от операций с иностранной валютой</li>
                </ul>
                В общем случае, мы рекомендуем использовать код дохода по 
                сделкам с Forex <b>1520</b>.
                <br/>
                Код дохода <b>1535</b> при условии, что на счету имеются другие 
                сделки производными финансовыми инструментами может быть 
                потенциально более выгоден, т.к. позволит сальдировать, 
                например, убыточные сделки по Forex с прибыльными сделками по 
                ПФИ, однако следует иметь в виду, что не все налоговые 
                инспектора могут согласиться с таким расчетом.
                </div>
              </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(HelpIBForex);
